// Manage footer menu state in "mobile" view
document.addEventListener("DOMContentLoaded", function() {
  const footerMenus = document.querySelectorAll("[data-action='footer-toggle']");

  footerMenus.forEach(function(footerMenu) {
    footerMenu.addEventListener("click", function(event) {
      toggleFooterMenu(footerMenu);
    });
  });

  const toggleFooterMenu = (el) => {
    const menu = document.getElementById(el.getAttribute('aria-controls'))

    // override old template CSS to show menu when toggled
    menu.style.maxHeight = 'none';
    menu.style.transition = 'none';
    menu.style.overflow = 'visible';

    if(el.getAttribute('aria-expanded') === 'true') {
      el.setAttribute('aria-expanded', 'false');
      menu.classList.remove('em-open');
    } else {
      el.setAttribute('aria-expanded', 'true');
      menu.classList.add('em-open');;
    }
  }
});